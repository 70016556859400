<template>
  <div>
    <DataTable
      :cols="cols"
      ref="dataTable"
      default-order-by="created_at"
      path="vehicles"
    >
      <template v-slot:item.for_manifest="{ item: vehicle }">
        <v-icon v-if="vehicle.for_manifest" small color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else small color="red">mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:item.actions="{ item: vehicle }">
        <VehicleActionCell v-bind="{ vehicle }" @vehicle:updated="vehicle => $refs.dataTable.getData()" />
      </template>
    </DataTable>
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'
import VehicleActionCell from '@/components/vehicles/VehicleActionCell'

export default {
  name: 'VehiclesTable',

  components: {
    DataTable,
    VehicleActionCell
  },

  data () {
    return {
      cols: [
        { text: 'Name', value: 'name' },
        { text: 'VRN', value: 'vin' },
        { text: 'Bike Capacity', value: 'max_bikes' },
        { text: 'Use For Manifests', value: 'for_manifest' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  }
}
</script>
