<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Edit Vehicle - {{ vehicle.name }}</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <VehicleFields :form="form" />

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="updateVehicle" :loading="loading">Update Vehicle <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Vehicle updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the vehicle.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'
import VehicleFields from '@/components/vehicles/VehicleFields'

export default {
  name: 'EditVehicleDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    VehicleFields,
    SnackBar,
    FormErrors
  },

  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      error: null
    }
  },

  watch: {
    dialog: {
      handler: function () {
        this.form = {
          ...this.vehicle
        }
      },
      immediate: true
    }
  },

  methods: {
    updateVehicle () {
      if (this.$refs.form.validate()) {
        this.loading = true

        this.$api.persist('put', {
          path: `vehicles/${this.vehicle.id}`,
          object: this.form
        })
          .then(({ data: vehicle }) => {
            this.$refs.successBar.open()
            this.$emit('vehicle:updated', vehicle)
            this.closeDialog()
          })
          .catch(error => {
            this.error = error
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
