<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Add Vehicle</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <VehicleFields :form="form" />

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" text @click="createVehicle" :loading="loading">Add Vehicle <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Vehicle created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the vehicle.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'
import VehicleFields from '@/components/vehicles/VehicleFields'

export default {
  name: 'AddVehicleDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    VehicleFields,
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false,
      error: null
    }
  },

  methods: {
    createVehicle () {
      if (this.$refs.form.validate()) {
        this.$refs.formErrors.clear()
        this.loading = true

        this.$api.persist('post', {
          path: 'vehicles',
          object: this.form
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.error = error
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
