<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="5"
      >
        <v-text-field
          v-model="form.name"
          :rules="[v => !!v || 'Vehicle name is required']"
          label="Vehicle Name"
          outlined
          required
          dense
        />
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="form.vin"
          :rules="[v => !!v || 'VRN is required']"
          label="VRN"
          outlined
          required
          dense
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="form.max_bikes"
          :rules="[v => !!v || 'Max bikes is required']"
          label="Max Bikes"
          type="number"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="5"
      >
        <v-checkbox
          v-model="form.for_manifest"
          label="Use for manifests?"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VehicleFields',

  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
