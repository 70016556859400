<template>
  <v-container fluid>
    <v-btn
      class="mt-4"
      color="primary"
      @click="dialog = true"
    >
      Add Vehicle
      <v-icon
        small
        right
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>

    <VehiclesTable />

    <AddVehicleDialog
      :dialog.sync="dialog"
    />
  </v-container>
</template>
<script type="text/javascript">
import VehiclesTable from '@/components/vehicles/VehiclesTable'
import AddVehicleDialog from '@/components/vehicles/AddVehicleDialog'

export default {
  name: 'Vehicles',

  components: {
    VehiclesTable,
    AddVehicleDialog
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>
