<template>
  <div>
    <v-btn depressed small outlined color="primary" @click.stop="dialog = true">
      Edit
    </v-btn>

    <EditVehicleDialog :vehicle="vehicle" :dialog.sync="dialog" @vehicle:updated="vehicle => $emit('vehicle:updated', vehicle)" />
  </div>
</template>

<script>
import EditVehicleDialog from '@/components/vehicles/EditVehicleDialog'

export default {
  name: 'VehicleActionCell',

  components: {
    EditVehicleDialog
  },

  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>